import React, { FC, HTMLAttributes, ReactNode } from "react";
import classNames from "classnames";

interface WrapperProps extends HTMLAttributes<HTMLElement> {
  error?: boolean;
  children?: ReactNode;
}

const Wrapper: FC<WrapperProps> = ({
  children,
  error,
  className = "",
  ...props
}) => {
  const classes = classNames("block relative", className, {
    errors: error,
    "mb-6": !className?.includes("mb-"),
    "w-full": !className?.includes("w-"),
  });
  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

interface TitleProps {
  className?: string;
  children?: ReactNode;
}

const Title: FC<TitleProps> = ({ children, className }) => {
  const classes = classNames("text-base", className);
  return <h4 className={classes}>{children}</h4>;
};

const Help: FC<{ children: ReactNode }> = ({ children }) => (
  <h5 className="text-base text-gray-600 mb-5">{children}</h5>
);

const Error: FC<{ children: ReactNode }> = ({ children }) => (
  <h4 className="text-xs sm:text-sm text-red-500 mt-2">{children}</h4>
);

interface FieldError {
  message?: string;
}

interface Props {
  title?: string;
  displayError?: boolean;
  size?: "xs";
  help?: string;
  error?: string | FieldError;
  titleClassName?: string;
  className?: string;
  required?: boolean;
  children?: ReactNode;
}

export const FormGroup: FC<Props> = ({
  title,
  help,
  error,
  displayError = true,
  className = "",
  size,
  titleClassName,
  children,
  required = false,
}) => {
  const errorMesssage = error
    ? typeof error === "string"
      ? error
      : error.message
    : undefined;

  const titleClasses = classNames(titleClassName, {
    "mb-2": !size,
    "mb-0 -mb-1": size === "xs",
  });

  const childrenClasses = classNames({
    "text-gray-500": typeof children === "string",
  });
  return (
    <Wrapper error={!!error} className={className}>
      {(title || help) && (
        <div className={titleClasses}>
          {title && (
            <Title className={`${required ? "required" : ""}`}>{title}</Title>
          )}
          {help && <Help>{help}</Help>}
        </div>
      )}
      <span className={childrenClasses}>{children}</span>

      {displayError && errorMesssage && <Error>{errorMesssage}</Error>}
    </Wrapper>
  );
};
