import React, { FC, HTMLProps, ReactNode, Ref } from "react";
import classNames from "classnames";

interface Props extends HTMLProps<HTMLInputElement> {
  addonBefore?: string;
  addonAfter?: string;
  fieldSize?: "md" | "base";
  children?: ReactNode;
}

const Addon: FC<HTMLProps<HTMLLIElement> & { children: ReactNode }> = ({
  children,
  className,
}) => {
  const classes = classNames(
    "bg-gray-300 text-lg px-4 flex items-center justify-center h-12 truncate w-16 text-center",
    className
  );

  return (
    <label title={children?.toString()} className={classes}>
      {children}
    </label>
  );
};

export const Input: FC<Props> = React.forwardRef(
  (
    { className = "", addonBefore, addonAfter, ...props },
    ref: Ref<HTMLInputElement>
  ) => {
    const classes = classNames("bg-white outline-none w-full sm", {
      "rounded-l": !addonBefore,
      "p-3": props.fieldSize === "base" || props.fieldSize == null,
      "text-sm p-2": props.fieldSize == "md",
      "rounded-r": !addonAfter,
      rounded: !addonAfter && !addonBefore,
      "w-full": !className.includes("w-"),
      "opacity-50 bg-gray-200": props.disabled,
    });

    return (
      <div
        className={`flex items-center shadow  ${
          className.includes("rounded") ? "" : "rounded"
        } border ${
          className.includes("border-") ? "" : "border-gray-400"
        } ${className}`}
      >
        {addonBefore && <Addon>{addonBefore}</Addon>}
        <input {...props} className={classes} ref={ref} />
        {addonAfter && <Addon>{addonAfter}</Addon>}
      </div>
    );
  }
);

Input.displayName = "Input";
