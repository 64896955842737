import React, { FC, FormHTMLAttributes, ReactNode } from "react";
import classNames from "classnames";

export const Form: FC<
  FormHTMLAttributes<HTMLFormElement> & { children: ReactNode }
> = ({ children, className = "", ...props }) => {
  const classes = classNames("m-auto w-full", className, {
    "mb-10": !className.includes("mb"),
  });
  return (
    <form noValidate={true} {...props} className={classes}>
      {children}
    </form>
  );
};
