import classNames from "classnames";
import React, { FC, HTMLProps, Ref, useEffect, useState } from "react";
import Icon from "../Icon";

type Props = HTMLProps<HTMLInputElement> & {
  onValueChange: (value: number) => void;
  min?: number;
  max?: number;
  incrementDisabled?: boolean;
  decrementDisabled?: boolean;
};

export const InputIncrement: FC<Props> = React.forwardRef(
  (
    {
      defaultValue,
      value,
      onValueChange,
      min = 0,
      max = 100,
      incrementDisabled = false,
      decrementDisabled,
      ...props
    },
    ref: Ref<HTMLInputElement>
  ) => {
    const [currentValue, setCurrentValue] = useState(() => {
      const defaultCurrentValue = Number(defaultValue || value);

      if (defaultCurrentValue < min || defaultCurrentValue > max) {
        return 0;
      }

      return defaultCurrentValue;
    });

    const setValue = (value: number) => {
      onValueChange && onValueChange(value);
      setCurrentValue(value);
    };

    useEffect(() => {
      setValue(Number(value));
    }, [value]);

    const isPlusButtonDisabled = currentValue > max - 1 || incrementDisabled;
    const isMinusButtonDisabled = decrementDisabled ?? !(currentValue > min);

    const PlusButtonClassName = classNames("focus:outline-none", {
      "opacity-20 cursor-not-allowed": isPlusButtonDisabled,
    });

    const MinusButtonClassName = classNames("focus:outline-none", {
      "opacity-20 cursor-not-allowed": isMinusButtonDisabled,
    });

    return (
      <div className="flex items-center">
        <button
          disabled={isMinusButtonDisabled}
          className={MinusButtonClassName}
          onClick={() => setValue(currentValue - 1)}
        >
          <Icon
            icon="minus"
            className="cursor-pointer text-gray-600 hover:text-gray-900 transform scale-125"
          />
        </button>
        <div className="mx-4">{currentValue}</div>
        <button
          disabled={isPlusButtonDisabled}
          className={PlusButtonClassName}
          onClick={() => setValue(currentValue + 1)}
        >
          <Icon
            icon="plus"
            className="cursor-pointer text-gray-600 hover:text-gray-900 transform scale-125"
          />
        </button>
        <input
          defaultValue={defaultValue}
          value={currentValue}
          onChange={() => null}
          {...props}
          ref={ref}
          className="hidden"
        />
      </div>
    );
  }
);

InputIncrement.displayName = "InputIncrement";
