import React, { FC, HTMLProps, Ref, useEffect, useState } from "react";
import classNames from "classnames";
import Icon from "../Icon";
import { t, Trans } from "@helpers/translate";

interface Props extends HTMLProps<HTMLInputElement> {
  onClear?: () => void;
  showClear?: boolean;
  defaultValue?: string | string[] | undefined;
}

export const InputSearch: FC<Props> = React.forwardRef(
  (
    { className = "", defaultValue = "", onClear, ...props },
    ref: Ref<HTMLInputElement>
  ) => {
    const [inputValue, setInputValue] = useState<string>(
      Array.isArray(defaultValue)
        ? (defaultValue[0] as string)
        : defaultValue || ""
    );

    useEffect(() => {
      setInputValue(
        Array.isArray(defaultValue) ? defaultValue[0] : defaultValue || ""
      );
    }, [defaultValue]);

    const classes = classNames(
      "border shadow-sm w-full flex items-center relative",
      className,
      {
        "p-4":
          !className.includes("p-") &&
          !className.includes("px-") &&
          !className.includes("py-"),
        "bg-white": !className.includes("bg-"),
        "rounded-lg": !className.includes("rounded"),
        "text-lg": !className.includes("text"),
      }
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
      if (props.onChange) {
        props.onChange(e);
      }
    };

    const handleClear = () => {
      setInputValue("");
      if (onClear) {
        onClear();
      }
    };

    const inputClasses = classNames(
      "w-full outline-none bg-transparent pr-8" // Added consistent right padding for long text to not to overlap with "X"
    );

    return (
      <div className={classes}>
        <svg
          className="w-6 h-6 mr-3"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <title id="GWGLogoTitle">
            {t({
              id: "common.svg.title",
              message: `GoWithGuide`,
            })}
          </title>
          <desc id="GWGLogoDesc">
            <Trans id="common.svg.description">
              Find your perfect tour guide at GoWithGuide. Relax and cherish
              unforgettable tours with loved ones. Create personalized tours for
              a truly memorable trip!
            </Trans>
          </desc>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.5}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
        <input
          type="text"
          className={inputClasses}
          ref={ref}
          value={inputValue}
          onChange={handleChange}
          {...props}
        />

        {inputValue && (
          <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
            <Icon
              icon="close"
              size="sm"
              className="cursor-pointer text-gray-400 hover:text-gray-600 transition-colors"
              onClick={handleClear}
            />
          </div>
        )}
      </div>
    );
  }
);

InputSearch.displayName = "InputSearch";
