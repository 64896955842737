import React, { AnchorHTMLAttributes, ReactNode } from "react";
import { variants, sizes } from "./styles";
import classNames from "classnames";
import { ButtonProps } from "./Button";
import Link, { LinkProps } from "next/link";

type Props = ButtonProps &
  LinkProps &
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    ref?: React.Ref<HTMLAnchorElement>;
    disabled?: boolean;
    children?: ReactNode;
  };

export const ButtonLink = React.forwardRef<HTMLAnchorElement, Props>(
  (
    {
      children,
      variant = "primary",
      size = "md",
      rounded,
      className,
      ...props
    },
    ref
  ) => {
    const classes = classNames(
      "inline-block font-medium text-center border-0 no-underline pointer whitespace-no-wrap px-6",
      variants[variant],
      sizes[size],
      className,
      {
        "opacity-50 text-white cursor-not-allowed pointer-events-none":
          props.disabled,
        "rounded-full": rounded,
      }
    );

    // To avoid hydration errors when url is ready.
    if (props.href != null) {
      return (
        <Link
          ref={ref}
          {...props}
          onClick={(e) => {
            if (props.disabled) {
              e.preventDefault();
            }
          }}
        >
          <span className={classes}>{children}</span>
        </Link>
      );
    }

    return (
      <span ref={ref} className={classes}>
        {children}
      </span>
    );
  }
);

ButtonLink.displayName = "ButtonLink";
