import { useBlockScroll } from "@helpers/hooks/useBlockScroll";
import { t, Trans } from "@helpers/translate";
import classNames from "classnames";
import React, { FC, ReactNode, useState } from "react";
import Icon, { IconType } from "../Icon";
import { Button } from "../Button";

interface InputModalItemProps {
  onClick?: () => void;
  isActive?: boolean;
  children?: ReactNode;
}

const InputModalItem: FC<InputModalItemProps> = ({
  children,
  onClick,
  isActive = false,
}) => {
  const wrapperClass = classNames(
    "mx-1 my-0.5 p-2",
    "hover:bg-gray-100 rounded cursor-pointer whitespace-nowrap",
    "flex items-center",
    {
      "text-black": !isActive,
      "text-secondary": isActive,
    }
  );

  const checkboxClass = classNames(
    "flex items-center border p-0.5 rounded-full text-gray-400 mr-2",
    {
      "bg-white": !isActive,
      "border-secondary bg-secondary text-white": isActive,
    }
  );

  return (
    <div className={wrapperClass} onClick={onClick}>
      <div className={checkboxClass}>
        <Icon
          icon="check"
          size="xs"
          className={isActive ? "text-white" : "invisible"}
        />
      </div>
      {children}
    </div>
  );
};

interface ModalProps {
  setIsOpen: () => void;
  onReset?: () => void;
  onApply?: () => void;
  value?: string;
  BlockScroll?: boolean;
  showApply?: boolean;
  children?: ReactNode;
}

const Modal: FC<ModalProps> = ({
  children,
  value,
  setIsOpen,
  onApply,
  showApply = true,
  BlockScroll = true,
}) => {
  if (BlockScroll) useBlockScroll();

  const wrapperClass = classNames(
    "z-10 bg-white sm:w-82",
    "md:relative md:mt-1", // desktop
    "max-md:fixed w-full mx-min-w-full max-md:bottom-0 max-md:right-0 left-0  max-md:rounded-t-xl max-md:py-6", // mobile
    "border shadow-xl max-md:shadow-lg-inverted p-2 rounded-lg",
    {
      "-mb-4": value,
    }
  );

  return (
    <div className="z-10 fixed md:absolute w-full">
      <button
        onClick={() => setIsOpen()}
        tabIndex={-1}
        className="fixed inset-0 h-full w-full cursor-default"
      ></button>
      <div className={wrapperClass} style={{ minWidth: "100%" }}>
        {children}

        {showApply && (
          <div className="mt-2 p-1 pt-3 border-t text-right">
            <Button
              variant="black"
              size="md"
              className="py-1 px-6 inline-block ml-4 md:text-sm md:px-4"
              onClick={onApply}
              disabled={!onApply}
            >
              <Trans id="form.InputModal.apply">Apply</Trans>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

interface InputModalProps {
  title?: string;
  value?: string;
  className?: string;
  icon?: IconType;
  showErrorView?: boolean;
  setIsGlobalOpen?: (open: boolean) => void;
  onReset?: () => void;
  onApply?: () => void;
  checkIsOpen?: () => boolean;
  showApply?: boolean;
  children?: ReactNode;
}

interface InputModalComponents {
  Item: typeof InputModalItem;
}

export const InputModal: FC<InputModalProps> & InputModalComponents = ({
  onReset,
  onApply,
  children,
  title,
  value,
  icon,
  setIsGlobalOpen,
  checkIsOpen,
  showErrorView = false,
  className = "",
  showApply = true,
}) => {
  const borderColor = showErrorView
    ? "border-primary border-2 font-bold"
    : "border-gray-400 border";
  const textColor = showErrorView ? "text-primary" : "";

  const buttonClass = classNames(
    "flex items-center justify-between h-12",
    "px-3 py-3 rounded cursor-pointer",
    "whitespace-nowrap",
    `${borderColor} hover:border-gray-500 hover:text-black`,
    className
  );

  const [isOpenLocal, setIsOpenLocal] = useState(false);

  function isOpen() {
    if (checkIsOpen !== undefined) {
      return checkIsOpen();
    }
    return isOpenLocal;
  }

  function setIsOpen(value: boolean) {
    if (setIsGlobalOpen !== undefined) {
      setIsGlobalOpen(value);
    } else {
      setIsOpenLocal(value);
    }
  }

  return (
    <div className="relative">
      <div className={buttonClass} onClick={() => setIsOpen(!isOpen())}>
        <div className="flex items-center">
          {icon && (
            <Icon icon={icon} size="sm" className={`mr-2 ${textColor}`} />
          )}
          <div className={`${textColor}`}>{value || title}</div>
        </div>
        <svg
          className={`${textColor} h-4 w-4 ml-2 ${
            isOpen() ? "transform rotate-180" : ""
          } `}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <title id="GWGLogoTitle">
            {t({
              id: "common.svg.title",
              message: `GoWithGuide`,
            })}
          </title>
          <desc id="GWGLogoDesc">
            <Trans id="common.svg.description">
              Find your perfect tour guide at GoWithGuide. Relax and cherish
              unforgettable tours with loved ones. Create personalized tours for
              a truly memorable trip!
            </Trans>
          </desc>
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
      {isOpen() && (
        <Modal
          showApply={showApply}
          value={value}
          setIsOpen={() => setIsOpen(!isOpen())}
          onReset={onReset}
          BlockScroll={false}
          onApply={
            onApply
              ? () => {
                  onApply();
                  setIsOpen(false);
                }
              : undefined
          }
        >
          {children}
        </Modal>
      )}
    </div>
  );
};

InputModal.Item = InputModalItem;
