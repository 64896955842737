import classNames from "classnames";
import React, { FC, HTMLProps, ReactNode } from "react";
import { t, Trans } from "@helpers/translate";

export const Option: FC<
  HTMLProps<HTMLOptionElement> & { children?: ReactNode }
> = ({ children, selected, disabled, ...props }) => (
  <option
    role="option"
    disabled={disabled}
    selected={selected}
    aria-selected={selected}
    aria-disabled={disabled}
    {...props}
  >
    {children}
  </option>
);

type SelectProps = HTMLProps<HTMLSelectElement> & {
  variant?: "sm" | "md";
  onValueChanged?: (value: string) => void;
  isDisabled?: boolean;
  showErrorView?: boolean;
  children?: ReactNode;
};

export interface ComponentProps
  extends React.ForwardRefExoticComponent<
    SelectProps & React.RefAttributes<HTMLSelectElement>
  > {
  Option: typeof Option;
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      children,
      placeholder,
      isDisabled = false,
      onValueChanged,
      className = "",
      showErrorView = false,
      ...props
    },
    ref
  ) => {
    const borderColor = showErrorView
      ? "border-primary border-2 font-bold"
      : "border-gray-400 border";

    const textColor = showErrorView ? "text-primary" : "text-gray-700";

    const classes = classNames(
      `block appearance-none w-full h-12 ${
        isDisabled ? "bg-gwg_disabled_gray" : "bg-white"
      } ${borderColor} hover:border-gray-500 px-3 rounded shadow leading-tight focus:outline-none focus:shadow-outline`,
      {
        "opacity-50": props.disabled,
      }
    );

    const wrapperClassnames = classNames("inline-block relative", className, {
      "w-full": !className.includes("w-"),
    });

    // Placeholder option should not be disabled. So, by default placeholder will be shown else first value will be
    // loaded which will issue with 1 selection item.
    // https://travelience.atlassian.net/browse/TL-3669
    return (
      <div className={wrapperClassnames}>
        <select
          {...props}
          className={classes}
          ref={ref}
          onChange={(e) => {
            onValueChanged && onValueChanged(e.target.value);
          }}
        >
          {placeholder !== undefined && (
            <Option label="" value={""}>
              {placeholder}
            </Option>
          )}
          {children}
        </select>
        <div
          className={`pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 ${textColor}`}
        >
          <svg
            className="fill-current h-4 w-4 ml-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title id="GWGLogoTitle">
              {t({
                id: "common.svg.title",
                message: `GoWithGuide`,
              })}
            </title>
            <desc id="GWGLogoDesc">
              <Trans id="common.svg.description">
                Find your perfect tour guide at GoWithGuide. Relax and cherish
                unforgettable tours with loved ones. Create personalized tours
                for a truly memorable trip!
              </Trans>
            </desc>
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    );
  }
) as ComponentProps;

Select.displayName = "Select";

Select.Option = Option;
