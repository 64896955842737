import React, {
  FC,
  FormEvent,
  HTMLProps,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import classNames from "classnames";

type Props = HTMLProps<HTMLTextAreaElement> & { children?: ReactNode };

export const TextArea: FC<Props> = React.forwardRef(
  (
    { children, className = "", maxLength, onChange, defaultValue, ...props },
    ref
  ) => {
    const classes = classNames(
      "p-3 rounded bg-white  shadow outline-none",
      className,
      {
        "w-full": !className.includes("w-"),
        "border border-gray-400": !className.includes("border-"),
        "opacity-50 bg-gray-200": props.disabled,
      }
    );

    const [value, setValue] = useState(String(defaultValue));

    useEffect(() => {
      setValue(String(defaultValue));
    }, [defaultValue]);

    const onChangeHandler = (event: FormEvent<HTMLTextAreaElement>) => {
      onChange?.(event);
      setValue(String(event.currentTarget.value.trim() || ""));
    };

    const maxLengthCrossed = useMemo(() => {
      return value.length > (maxLength || 0);
    }, [value]);

    return (
      <div>
        <div>
          <textarea
            rows={5}
            defaultValue={defaultValue}
            className={classes}
            {...props}
            ref={ref}
            onChange={onChangeHandler}
          >
            {children}
          </textarea>
        </div>
        {maxLength && (
          <div className="flex">
            <p
              className={`ml-auto text-sm ${
                maxLengthCrossed
                  ? "text-primary-light font-bold"
                  : "text-gray-400"
              }`}
            >{`${String(value).trim().length}/${maxLength}`}</p>
          </div>
        )}
      </div>
    );
  }
);

TextArea.displayName = "TextArea";
